jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    });
        
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });

    // MOBILE MENU TOGGLE
    function animateMenu({classList}, action = '') {
        const $menuWrapper = jQuery('#side_menu_wrapper');
        const $mobileMenu = jQuery('#mobile_menu li');
        const $hamburgerIcon = jQuery('#hamburger-icon');
        const $stickyHamburgerIcon = jQuery('#hamburger-icon-sticky');
        const isClosed = $hamburgerIcon.hasClass('closed');
      
        function toggleMenu() {
          const easing = Power4.easeInOut;
          const duration = 0.5;
          const x = '0%';
          const height = isClosed ? '100%' : '0';
      
          $mobileMenu.each((i, el) => TweenLite.to(el, 0.2 * i, { ease: easing, opacity: isClosed ? 1 : 0 }));
          TweenLite.to($menuWrapper, duration, { ease: easing, x, height });
      
          $hamburgerIcon.toggleClass('closed', !isClosed);
          $stickyHamburgerIcon.toggleClass('closed', !isClosed);
          $hamburgerIcon.toggleClass('is-active');
          $stickyHamburgerIcon.toggleClass('is-active');
          jQuery('body').toggleClass('menu-open', isClosed);
          $menuWrapper.toggleClass('open');
        }
      
        toggleMenu();
    }

    jQuery("#hamburger-icon").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon-sticky").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery('#mobile_menu a').click(function(e) {
        animateMenu(jQuery(this), 'close');
    });

    // mobile menu scrolling
    jQuery('#scrollable-wrapper').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });

    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 200) {
            jQuery('#sticky-header').addClass('visible');
        } else {
            jQuery('#sticky-header').removeClass('visible');
        }
    });

    function scrollToTarget(target) {
        // Get the height of the offset element (replace header.banner with the selector of the element)
        var offsetHeight = jQuery('header.banner').outerHeight();
        // Get the top offset of the target element, subtract the offset height
        var targetTop = target.offset().top - offsetHeight;
      
        // Scroll to the target element with an offset
        window.scrollTo({
          top: targetTop,
          behavior: 'smooth'
        });
    }
    
    jQuery('a[href^="#"]:not([data-toggle="modal"]):not(.nav-link)').on('click', function(event) {
        event.preventDefault();
        var target = jQuery(this.getAttribute('href'));
        var url = window.location.href;
        url = url.replace(/#.*$/, '') + jQuery(this).attr('href');
        window.history.replaceState(null, null, url);
        var currentHref = window.location.href.split('#')[1];
        
        if (target.length) {
            event.preventDefault();
            scrollToTarget(target);
        } else {
            localStorage.setItem("hrefKey", '#' + currentHref);
            window.location.href='/';
        }
    });
        
    if(localStorage.getItem("hrefKey")) {
        var hrefLocal = localStorage.getItem("hrefKey");
        target = jQuery(hrefLocal);
        if(target.length) {
            scrollToTarget(target);
        }
        localStorage.clear();
    }
    
});